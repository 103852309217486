import React from "react";
import { Link } from "react-router-dom";

const BabyBanner = () => {
  return (
    <section
      className="relative bg-[url('https://res.cloudinary.com/dh19kkzt1/image/upload/v1742285563/banner_kqezyf.jpg')] bg-cover bg-center bg-no-repeat"
    >
      <div className="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 sm:bg-gradient-to-r"></div>

      <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
        <div className="max-w-xl text-center sm:text-left">
          <h1 className="text-3xl font-extrabold sm:text-5xl">
          Designed for Tiny hands,
            <strong className="block font-extrabold text-[#1073d9]"> built for big dreams </strong>
          </h1>

          <p className="mt-4 max-w-lg sm:text-xl/relaxed">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus
            numquam ea!
          </p>

          <div className="mt-8 flex flex-wrap gap-4 text-center">
            <Link
              to="/product"
              className="block w-full rounded-sm bg-[#1073d9] px-12 py-3 text-sm font-medium text-white shadow-sm  focus:ring-3 focus:outline-none sm:w-auto"
            >
             Shop Now
            </Link>

            <a
              href="#"
              className="block w-full rounded-sm bg-white px-12 py-3 text-sm font-medium text-[#1073d9] shadow-sm hover:text-white-700 focus:ring-3 focus:outline-none sm:w-auto"
            >
             Get Offer
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BabyBanner;

